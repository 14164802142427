import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import axios from "axios";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { faUserAstronaut } from '@fortawesome/free-solid-svg-icons'
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
// import FontAwesomeIcon from "@fortawesome/vue-fontawesome";
// import VueGtag from "vue-gtag";
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import JQuery from "jquery";
import Embed from 'v-video-embed'
import VueSmoothScroll from 'vue2-smooth-scroll'
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import * as CryptoJS from 'crypto-js';
// We are only using the user-astronaut icon
library.add(faUserAstronaut, faPlus)

// Replace any existing <i> tags with <svg> and set up a MutationObserver to
// continue doing this as the DOM changes.
dom.watch()
Vue.config.productionTip = false
Vue.use(Embed);
Vue.use(VueSmoothScroll)
Vue.use(VueLodash, { name: 'custom' , lodash: lodash })
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
import VueSocketIO from 'vue-socket.io'
Vue.use(new VueSocketIO({
  debug: true,
  connection: 'https://ocs.golf',
  options: { 
    path: '/api/socket.io',
    transports: ['websocket', 'polling'],
  } //Optional options
}))
// Vue.component("font-awesome-icon", FontAwesomeIcon);
// Vue.use(VueGtag, {
//   config: { id: "" },
//   includes: [
//     { id: '' }
//   ]
// }, router);
new Vue({
  router,
  axios,
  JQuery,
  CryptoJS,
  render: h => h(App),
}).$mount('#app')
