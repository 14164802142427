<template>
  <div id="app">
    <Menu />
      <transition name="fade" appear>
        <router-view :key="$route.fullPath"/>
      </transition>
    <Footer />
  </div>
</template>

<script>
import * as CryptoJS from 'crypto-js';
import Menu from './components/Menu.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    Menu,
    Footer
  },
  data: function() {
    const CryptoJSAesJson = {
      'stringify': (cipherParams) => {
        const j = {ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64)};

        if (cipherParams.iv)
          j.iv = cipherParams.iv.toString();

        if (cipherParams.salt)
          j.s = cipherParams.salt.toString();

        return JSON.stringify(j);
      },
      'parse': (jsonStr) => {
        const j = JSON.parse(jsonStr);
        const cipherParams = CryptoJS.lib.CipherParams.create({ciphertext: CryptoJS.enc.Base64.parse(j.ct)});

        if (j.iv)
          cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv);

        if (j.s)
          cipherParams.salt = CryptoJS.enc.Hex.parse(j.s);

        return cipherParams;
      },
    };
    return {
      collectData: [],
      collectDataAuth: [],
      memberships: [],
      cryptoAES: CryptoJSAesJson,
      cryptoKey: 'ocssport',
      password: 'LP-309974',
    };
  },
  methods: {
      encryptString: function(input) {
        return CryptoJS.AES.encrypt(input, this.cryptoKey).toString();
      },
      hashString(input) {
        return CryptoJS.SHA256(input).toString(CryptoJS.enc.Hex);
      },
      base64Decode(input) {
        const decStr = atob(input);
        const bytes = new Uint8Array(decStr.length);

        for (let i = 0; i < bytes.length; i++)
          bytes[i] = decStr.charCodeAt(i);

        return String.fromCharCode(...new Uint16Array(bytes.buffer));
      },
      socketAuthStr() {
        let sktMsg = '';
        // sktMsg += 'EG' + ' ';
        // sktMsg += '2000029000' + ' ';
        sktMsg += 'GUESTUSER@OCS-SOFTWARE.COM' + ' ';
        sktMsg += "'" + this.encryptString(this.password) + "' ";
        // sktMsg += 'a ';
        return sktMsg;
      },
      userLogin() {
				let sktMsg = '';
        sktMsg += 'EG '
				sktMsg += this.socketAuthStr();
				sktMsg += 'a';
        this.$socket.emit('mauth', sktMsg);
      },
			getFacGroups() {
				let sktMsg = '';
        sktMsg += '2000029000 '
				sktMsg += this.socketAuthStr();
				sktMsg += '.';
        console.log('gl', sktMsg)
				this.$socket.emit('gl', sktMsg);
			},
      dataCollect() {
        this.sockets.subscribe('respgl', (data) => {
          // this.$root.debugLog('respgl');
          // this.$root.debugLog(data);

          try {
            const jsonDoc = JSON.parse(data);

            this.getAuthData(jsonDoc);
            this.collectData = jsonDoc;
          }
          catch (e) {
            this.$root.debugLog(e);
          }
        });
      },
      dataCollectMAuth() {
        this.sockets.subscribe('respmauth', (data) => {
          // this.$root.debugLog('respgl');
          // this.$root.debugLog(data);

          try {
            const jsonDocAuth = JSON.parse(data);
            this.collectDataAuth = jsonDocAuth;
            console.log("this.collectDataAuth")
            console.log(this.collectDataAuth)
            // uckfield club below
            this.memberships = this.collectDataAuth.my_clubs[1];
            console.log("this.memberships")
            console.log(this.memberships)
            this.getFacGroups();
          }
          catch (e) {
            this.$root.debugLog(e);
          }
        });
      }
			// encryptPassword(input) {
			// 	return CryptoJS.AES.encrypt(input, appConfig.cryptPasswd).toString();
			// },
			// encryptString(input, passw) {
			// 	return Base64.encode(CryptoJS.AES.encrypt(JSON.stringify(input), passw, {format: this.cryptoAES}).toString());
			// },
  },
  mounted() {
    // this.getAuthData();
    this.userLogin();
    this.dataCollect();
    this.dataCollectMAuth();
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}
@media (min-width: 1200px) {
    .container, .container-sm, .container-md, .container-lg, .container-xl {
        max-width: 1400px!important;
    }
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
  overflow-x: hidden;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-active {
  transition-delay: 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
